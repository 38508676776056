import MetaData from "../../../../interfaces/metadata";
import {Button} from "react-bootstrap";
import {updateUserMeta} from "../../../../utils/firebase/firestore";
import {useUser} from "../../../../contexts/user_context";
import {LocalUser} from "../../../../interfaces/user";
import {BUTTON_LAYOUTS} from "../../../../utils/config/buttons";

export default function DesignButtons() {
    const [user,setUser]=useUser();
    async function updateButtonType(type:MetaData['buttonType']){
        if(!user)return;
        await updateUserMeta(user,{...user.meta,buttonType:type});
        setUser((old:LocalUser)=>{return {...old,meta:{...old.meta,buttonType:type}}})
    }

    return (
        <div>
            {BUTTON_LAYOUTS.map(type=><Button key={type} className={'m-2'} onClick={()=>updateButtonType(type)}>{type}</Button>)}
        </div>
    );
}