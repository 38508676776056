import { Card } from "react-bootstrap";
import { useUser } from "../../../../../contexts/user_context";
import Folder from "../../../../../interfaces/folder";
import { MainLink } from "../../../../../interfaces/main_link";

export default function FolderCard({ folder,addFolderToHierarchy }: { folder: Folder,addFolderToHierarchy:Function }) {
  const [user] = useUser();

  const links = user?.links.filter(
    (link: MainLink) => link.folder === folder.uid
  );

  return (
    <Card className={"shadow-2 m-3 round-border pointer-on-hover"} onClick={()=>addFolderToHierarchy(folder.uid)} style={{ minWidth: 150 }}>
      <Card.Body className="p-3">
        <svg
          width="56"
          height="47"
          viewBox="0 0 56 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.5551 7.16957H48.8297C49.826 7.16947 50.8113 7.37701 51.7229 7.77895C52.6346 8.1809 53.4524 8.76842 54.1243 9.50406C54.7961 10.2397 55.3073 11.1073 55.6252 12.0515C55.9431 12.9957 56.0607 13.9958 55.9706 14.988L53.6871 40.0815C53.5252 41.8628 52.7033 43.5193 51.3828 44.7258C50.0622 45.9322 48.3384 46.6015 46.5498 46.6022H9.45082C7.66215 46.6015 5.93834 45.9322 4.61781 44.7258C3.29727 43.5193 2.47539 41.8628 2.31352 40.0815L0.0300075 14.988C-0.122754 13.3275 0.311033 11.6659 1.256 10.2919L1.1162 7.16957C1.1162 5.26808 1.87156 3.44447 3.21612 2.09992C4.56067 0.755363 6.38428 0 8.28577 0H21.4491C23.3504 0.000406066 25.1737 0.756036 26.518 2.10068L29.4862 5.06889C30.8304 6.41353 32.6537 7.16916 34.5551 7.16957ZM4.72249 7.59974C5.48964 7.32013 6.31414 7.16957 7.17448 7.16957H26.518L23.9835 4.63513C23.3114 3.9628 22.3998 3.58499 21.4491 3.58478H8.28577C7.34671 3.58462 6.44508 3.95293 5.77472 4.61055C5.10437 5.26817 4.71882 6.16257 4.70098 7.10146L4.72249 7.59974Z"
            fill="#FEBF32"
          />
        </svg>

        <div className="h4 mt-2">{folder.name}</div>
        {links && <div className="text-muted">{links.length} Links</div>}
      </Card.Body>
    </Card>
  );
}
