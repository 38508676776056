import React, { useEffect, useState } from "react";
import { MainLink } from "../../../../../interfaces/main_link";
import { Button, Card, Form } from "react-bootstrap";
import { useUser } from "../../../../../contexts/user_context";
import { LocalUser } from "../../../../../interfaces/user";
import "./link_card.css";
import plus from "../../../../../static/plus.png";
import { uploadButtonImage } from "../../../../../utils/firebase/storage";
import {
    updateUserLinks,
    updateUserMeta,
} from "../../../../../utils/firebase/firestore";
import folder_logo_black from '../../../../../static/folder_logo_black.png';
function LinkCard({ link,setShowAddFolderModal }: { link: MainLink,setShowAddFolderModal:Function }) {
    function onImageChange(e: any) {
        const file=e.target.files[0];
        if (!file.type.startsWith("image/")) {
            alert("Kindly provide a Image file");
            return;
        }
        setImage(file);
    }

    const [shared, setShared] = useState<boolean>(link.shared);
    const [user, setUser] = useUser();
    const [editable, setEditable] = useState(link.title === "~");
    const [title, setTitle] = useState(link.title === "~" ? "" : link.title);
    const [description, setDescription] = useState(
        link.description ? link.description : ""
    );
    const [url, setURL] = useState(link.url);
    const [image, setImage] = useState<any>();
    const [selectedFolder, setSelectedFolder] = useState<MainLink["folder"]>(
        link.folder
    );
    const [titleError, setTitleError] = useState("");
    const [urlError, setURLError] = useState("");
    
    useEffect(() => {
        if (user && shared !== link.shared) {
            let index = user?.links.findIndex(
                (localLink) => link.uid === localLink.uid
            );
            let localLinks = [...user.links];
            localLinks[index].shared = shared;
            let sharedLinks = user.meta.sharedLinks;
            if (shared) {
                sharedLinks.push(localLinks[index]);
            } else
                sharedLinks = user.meta.sharedLinks.filter(
                    (slink) => slink.uid !== link.uid
                );
            setTimeout(() => {
                setUser((old: LocalUser) => {
                    const newUser = {
                        ...old,
                        links: localLinks,
                        meta: { ...old.meta, sharedLinks },
                    };
                    updateUserLinks(newUser);
                    updateUserMeta(user, { ...old.meta, sharedLinks });
                    return newUser;
                });
            }, 275);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shared]);

    async function onSave(folderUid: MainLink["folder"] | undefined) {
        setTitleError("");
        setURLError("");
        if (title === "") {
            setTitleError("Required Field");
            return;
        }

        if (url === "") {
            setURLError("Required Field");
            return;
        }

        let imageURL = link.imageURL;
        if (image) {
            imageURL = await uploadButtonImage(user, image);
        }
        const folder_uid = folderUid !== undefined ? folderUid : selectedFolder;
        if (!user) return;
        let loopLink = user.links.find((ulink) => ulink.uid === link.uid);
        if (loopLink) {
            loopLink.title = title;
            loopLink.description = description;
            if (!url.startsWith("http")) {
                loopLink.url = "http://" + url;
                setURL("http://" + url);
            } else loopLink.url = url;
            loopLink.imageURL = imageURL;
            loopLink.folder = folder_uid === undefined ? null : folder_uid;

            if (loopLink.shared) {
                let foundShareLink = user.meta.sharedLinks.findIndex(
                    (slink) => slink.uid === link.uid
                );
                if (foundShareLink!==-1) {
                    user.meta.sharedLinks[foundShareLink] = { ...loopLink };

                } else user.meta.sharedLinks.push(loopLink);
            }
        }
        updateUserLinks({ ...user });
        updateUserMeta(user, user.meta);
        setUser({ ...user });
        setEditable(false);
    }

    function onCancel() {
        if (link.title === "~") onDelete();
        setTitle(link.title);
        setDescription(link.description ? link.description : "");
        setURL(link.url);
        setImage(null);
        setEditable(false);
    }

    function onDelete() {
        // if(sharedLinks&&user){
        //     let index=user?.links.findIndex((localLink)=>link.title===localLink.title)
        //     let localLinks=[...user.links];
        //     localLinks[index].shared=false;
        //     setUser((old:LocalUser)=>({...old,links:localLinks}));
        //
        // } else
        setUser((old: LocalUser) => {
            const newUser = {
                ...old,
                links: old.links.filter(
                    (localLink) => localLink.uid !== link.uid
                ),
                meta: {
                    ...old.meta,
                    sharedLinks: old.meta.sharedLinks.filter(
                        (localLink) => localLink.uid !== link.uid
                    ),
                },
            };
            updateUserLinks(newUser);
            return newUser;
        });
    }

    return (
        <Card className={"shadow-2 m-3 link-card"}>
            <Card.Body>
                {editable ? (
                    <Form>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                placeholder={"Title of the link"}
                                className={"link-card-input"}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <Form.Text className="text-danger">
                                {titleError}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Url</Form.Label>
                            <Form.Control
                                placeholder={"Link url"}
                                className={"link-card-input"}
                                value={url}
                                onChange={(e) => setURL(e.target.value)}
                            />
                            <Form.Text className="text-danger">
                                {urlError}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description (Optional)</Form.Label>
                            <Form.Control
                                placeholder={"Write a detailed description"}
                                className={"link-card-input"}
                                as={"textarea"}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Folder (Optional)</Form.Label>
                            <Form.Control
                                as={"select"}
                                value={
                                    selectedFolder === null
                                        ? "undefined"
                                        : selectedFolder
                                }
                                onChange={(e) =>
                                    {if(e.target.value==="new"){
                                        setShowAddFolderModal(true);
                                        setSelectedFolder(null);
                                        return;
                                    }
                                    setSelectedFolder(
                                        e.target.value === "undefined"
                                            ? null
                                            : e.target.value
                                    )}
                                }
                            >
                                <option value={"undefined"}>
                                    Select Folder
                                </option>
                                {user?.folders.map((folder) => (
                                    <option key={folder.uid} value={folder.uid}>
                                        {folder.name}
                                    </option>
                                ))}
                                <option value={"new"}>
                                    Add Folder
                                </option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Add Image (Optional)</Form.Label>
                            <div
                                className="d-flex align-items-center justify-content-center pointer-on-hover"
                                style={{
                                    backgroundColor: "#F4F5F8",
                                    height: 109,
                                    width: 92,
                                }}
                                onClick={() =>
                                    document
                                        .getElementById("choose_pp")
                                        ?.click()
                                }
                            >
                                <img
                                    alt="plus"
                                    height={image || link.imageURL ? 109 : 27}
                                    width={image || link.imageURL ? 92 : 27}
                                    src={
                                        image
                                            ? URL.createObjectURL(image)
                                            : !link.imageURL
                                            ? plus
                                            : link.imageURL
                                    }
                                />
                                <input
                                    id="choose_pp"
                                    style={{
                                        display: "none",
                                    }}
                                    type="file"
                                    accept="image/*"
                                    onChange={onImageChange}
                                />
                            </div>
                        </Form.Group>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button
                                block={true}
                                className={"round-border col-5 col-lg-3 mx-2"}
                                variant={"outline-primary"}
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                block={true}
                                className={
                                    "round-border col-5 col-lg-3 mx-2 my-0"
                                }
                                variant={"primary"}
                                onClick={() => onSave(undefined)}
                            >
                                Save
                            </Button>
                        </div>
                    </Form>
                ) : (
                    <div>
                        <div className="d-flex align-items-start justify-content-between">
                            <div style={{ maxWidth: "70%" }}>
                                <h4 className="mb-0">{link.title}</h4>
                                <a href={link.url} className="text-muted">
                                    {link.url}
                                </a>
                            </div>
                            <div>
                                <img
                                    className={"pointer-on-hover mx-2 "}
                                    onClick={() => setEditable(true)}
                                    alt="edit"
                                    src="https://img.icons8.com/material-rounded/24/000000/edit--v1.png"
                                />
                                <img
                                    className={"pointer-on-hover mx-2"}
                                    onClick={onDelete}
                                    alt="delete"
                                    src="https://img.icons8.com/material/24/000000/delete-forever--v2.png"
                                />
                            </div>
                        </div>
                        <small className="text-muted">{link.description}</small>
                        <hr />
                        <div
                            className={
                                "d-flex align-items-center justify-content-end"
                            }
                        >
                            <img src={folder_logo_black} alt="folder" className="mr-2"/>
                            <Form.Control
                                as={"select"}
                                className="w-50 mr-3"
                                value={
                                    selectedFolder === null
                                        ? "undefined"
                                        : selectedFolder
                                }
                                onChange={(e) => {
                                    if(e.target.value==="new"){
                                        setShowAddFolderModal(true);
                                        setSelectedFolder(null);
                                        return;
                                    }
                                    setSelectedFolder(
                                        e.target.value === "undefined"
                                            ? null
                                            : e.target.value
                                    );
                                    onSave(
                                        e.target.value === "undefined"
                                            ? null
                                            : e.target.value
                                    );
                                }}
                            >
                                <option value={"undefined"}>No folder</option>
                                {user?.folders.map((folder) => (
                                    <option key={folder.uid} value={folder.uid}>
                                        {folder.name}
                                    </option>
                                ))}
                                <option value={"new"}>Add folder</option>

                            </Form.Control>
                            <Form.Switch
                                checked={shared}
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        if (
                                            window.confirm(
                                                `Do you want to disable this link`
                                            )
                                        )
                                            setShared(e.target.checked);
                                    } else setShared(e.target.checked);
                                }}
                                id={link.uid}
                            />
                        </div>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
}

export default LinkCard;
