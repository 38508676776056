import React from "react";
import { Accordion, Card } from "react-bootstrap";
// import { Card, Accordion } from "react-bootstrap";
import { MainLink } from "../../../interfaces/main_link";
import { LocalUser } from "../../../interfaces/user";
import { DEFAULT_SOCIAL_LINKS } from "../../../utils/config/social-links";
import PreviewButton from "./previewButton/preview_button";

function PreviewContent({
    user,
    allLinks,
}: {
    user: LocalUser | null | undefined;
    allLinks: boolean;
}) {
    let effectiveLinks;
    if (allLinks) {
        effectiveLinks = user?.links?.filter(
            (link) =>
                !link.socialLink && link.folder === null && link.title !== "~"
        );
    } else {
        effectiveLinks = user?.meta?.sharedLinks?.filter(
            (link) => !link.socialLink
        );
        effectiveLinks?.reverse();
    }
    return (
        <div className="d-flex flex-column align-items-center w-100">
            <img
                alt="user"
                className="rounded-circle mt-3 mb-2"
                height={100}
                width={100}
                src={
                    user?.profile_pic_url
                        ? user?.profile_pic_url
                        : "https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg"
                }
            />
            <div>@{user?.username}</div>
            <div className={"d-flex my-3"}>
                {user?.links
                    ?.filter((link) => link.socialLink && link.shared)
                    .map((socialLink) => {
                        let effectiveURL = socialLink.url;
                        if (socialLink.title === "phone") {
                            effectiveURL = "tel:" + effectiveURL;
                        }
                        return (
                            <a href={effectiveURL}>
                                <img
                                    className="pointer-on-hover"
                                    key={socialLink.uid}
                                    alt={socialLink.title}
                                    src={DEFAULT_SOCIAL_LINKS[socialLink.title]}
                                />
                            </a>
                        );
                    })}
            </div>
            <div className="d-flex w-75 flex-wrap justify-content-center">
                {allLinks && (
                    <Accordion className="w-100 mb-4" defaultActiveKey="0">
                        {user?.folders.map((folder, index) => {
                            const links = user?.links.filter(
                                (link) =>
                                    !link.socialLink &&
                                    (allLinks || link.shared) &&
                                    link.folder === folder.uid
                            );
                            if (links.length === 0) return null;
                            return (
                                <Card key={folder.uid}>
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={index.toString()}
                                    >
                                        {folder.name}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse
                                        eventKey={index.toString()}
                                    >
                                        <Card.Body className="d-flex justify-content-center flex-wrap">
                                            {links.map(
                                                (
                                                    link: MainLink,
                                                    index: number
                                                ) => {
                                                    if (link.title !== "~")
                                                        return (
                                                            <PreviewButton
                                                                meta={
                                                                    user?.meta
                                                                }
                                                                link={link}
                                                                key={link.uid}
                                                            />
                                                        );
                                                    return null;
                                                }
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            );
                        })}
                    </Accordion>
                )}
                {user &&
                    effectiveLinks?.map((link) => (
                        <PreviewButton
                            meta={user?.meta}
                            link={link}
                            key={link.uid}
                        />
                    ))}
            </div>
        </div>
    );
}

export default PreviewContent;
