import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PreviewStructure from "./preview/preview_structure";
import NavigationBar from "../../components/navigationBar/navigation_bar";
import { useUser } from "../../contexts/user_context";
import { push as Menu } from "react-burger-menu";
import Sidebar from "./sidebar/sidebar";
import Design from "./design/design";
import SharedLinks from "./sharedLinks/shared_links";
import AllLinks from "./allLinks/all_links";
import Bookmarks from "./bookmarks/bookmarks";
import Settings from "./settings/settings";
var styles = {
    bmBurgerButton: {
        display: "none",
    },
    bmBurgerBars: {
        background: "#373a47",
    },
    bmBurgerBarsHover: {
        background: "#a90000",
    },
    bmCrossButton: {
        height: "24px",
        width: "24px",
    },
    bmCross: {
        background: "#bdc3c7",
    },
    bmMenuWrap: {
        position: "fixed",
        height: "96vh",
        maxWidth: '245px',
    },
    bmMenu: {
        background: "#ffffff",
        fontSize: "1.15em",
        borderStyle: "dashed",
        borderWidth: "1px",
        borderColor: "black",
        maxWidth: '245px',
    },
    bmMorphShape: {
        fill: "#304abc",
    },
    bmItemList: {
        color: "#b8b7ad",
    },
    bmItem: {
        display: "fixed",
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0)",
    },
};
function Home() {
    const [user] = useUser();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selected, setSelected] = useState("All Links");
    function toggleSidebar() {
        setSidebarOpen((old) => !old);
    }
    return (
        <Container
            style={{ backgroundColor: "#fafbff" }}
            className=" p-3   min-vh-100 "
            id="outer-container"
            fluid={true}
        >
            <Menu
                className={"mx-0"}
                onClose={() => setSidebarOpen(false)}
                isOpen={sidebarOpen}
                styles={styles}
                pageWrapId={"page-wrap"}
                outerContainerId={"outer-container"}
            >
                <Sidebar setSelected={setSelected} selected={selected} />
            </Menu>
            <div className=" d-flex flex-column" id={"page-wrap"}>
                <NavigationBar toggleSidebar={toggleSidebar} />

                <Row className="flex-grow-1 mt-2">
                    <Col xs={"auto"} className="d-none d-lg-block">
                        <Sidebar
                            setSelected={setSelected}
                            selected={selected}
                        />
                    </Col>
                    <Col>
                        <Row>
                            <Col  xs={12} lg={7}>
                                {selected === "Shared Links" && <SharedLinks />}
                                {selected === "All Links" && <AllLinks />}
                                {selected === "Design" && <Design />}
                                {selected === "Bookmarks" && <Bookmarks />}
                                {selected === "Settings" && <Settings />}
                            </Col>
                            <Col xs={12} lg={5}>
                                <PreviewStructure user={user} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default Home;
