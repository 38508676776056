import { MainLink } from "./main_link";
import MetaData, { defaultMetaData } from "./metadata";
import Folder from "./folder";

export interface LocalUser extends RemoteUser {
    uid: string;
    
}

export interface RemoteUser {
    name: string;
    bio: string;
    email: string;
    profile_pic_url: string;
    username: string;
    links: Array<MainLink>;
    meta: MetaData;
    folders: Folder[];
}
export interface SharedLinksDocument {
    name: string;
    bio: string;
    username: string;
    email: string;
    meta: MetaData;
    profile_pic_url: string;
}

export const defaultRemoteUser: RemoteUser = {
    name: "",
    bio: "",
    email: "",
    profile_pic_url: "",
    username: "",
    links: [],
    folders: [],
    meta: defaultMetaData,
};
export const defaultSharedLinksDocument: SharedLinksDocument = {
    name: "",
    bio: "",
    email: "",
    profile_pic_url: "",
    username: "",
    meta: defaultMetaData,
};
export const defaultLocalUser: LocalUser = {
    ...defaultRemoteUser,
    uid: "",
};
