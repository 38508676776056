import { useUser } from "../../../../contexts/user_context";
import FolderCard from "./folderCard/folder_card";

export default function Folders({parent, addFolderToHierarchy}:{parent:any,addFolderToHierarchy:Function}) {
  const [user] = useUser();
  const folders=user?.folders.filter(folder=>folder.parent===parent.uid);
  if(folders?.length===0)return null;
  return (
    <div>
      <h4 className="ml-3">Folders:</h4>
      <div className={"d-flex flex-wrap"}>
        {folders?.map((folder) => (
          <FolderCard key={folder.uid} folder={folder} addFolderToHierarchy={addFolderToHierarchy} />
        ))}
      </div>
    </div>
  );
}
