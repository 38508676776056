import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {signInWithEmailPassword, signUpWithGoogle} from "../../../utils/firebase/auth";
import { getUserDocument } from '../../../utils/firebase/firestore';

const errorTexts = {
    fieldRequired: "This field is required!",
    invalidEmail: "Invalid Email",
    invalidCredentials: "The email or password is incorrect"
}

function LoginForm() {
    const [errors, setErrors] = useState<any>({});
    const [loading,setLoading]=useState(false)
    const {register, handleSubmit} = useForm();

    async function loginWithGoogle(){
        setLoading(true)

        setErrors({})
        try{
            const result=await signUpWithGoogle();
            if(result&&result.user&&result.user.email){
                const user_data=await getUserDocument(result.user.email);
                if(!user_data){
                    setErrors({password:"No such user exists. Please sign up first!"})
                }
                
            }
        } catch(error){
            console.error(error);
        }
        
        setLoading(false)

    }

    function validateEmail(email: string) {
        if (email === '') {
            setErrors({ email: errorTexts.fieldRequired});
            return false;
        }
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            setErrors({ email: errorTexts.invalidEmail});
            return false;
        }
        return true;
    }

    function validatePassword(password: string) {
        if (password === '') {
            setErrors({password: errorTexts.fieldRequired});
            return false;
        }
        if (password.length < 6) {
            setErrors({password: errorTexts.invalidCredentials});
            return false;
        }
        return true;

    }

    async function onSubmit(data: any) {
        setLoading(true)
        const {email, password} = data;
        if (!validateEmail(email)) return;
        if (!validatePassword(password)) return;
        const result = await signInWithEmailPassword(email, password);
        if (result !== true) {
            switch (result.code) {
                case "auth/wrong-password": {
                    setErrors({...errors, password: errorTexts.invalidCredentials});
                    break;
                }
                default:
                    setErrors({...errors, password: result.message});
            }
        }
        setLoading(false)

    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
            <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control ref={register} name="email" type='email' placeholder="Email"/>
                {errors.email && <small className={'text-danger'}>{errors.email}</small>}
            </Form.Group>
            <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control ref={register} name="password" type='password' placeholder="Password"/>
                {errors.password && <small className={'text-danger'}>{errors.password}</small>}

            </Form.Group>
            <Button disabled={loading} variant={'outline-primary'} type={'submit'} block={true}>Sign In</Button>
            <Button disabled={loading} onClick={loginWithGoogle} variant={'primary'} block={true}>Sign In With Google</Button>
        </Form>
    );
}

export default LoginForm;