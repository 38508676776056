import React from "react";
import { MainLink } from "../../../../interfaces/main_link";
import LinkCard from "./linkCard/link_card";
import noData from "../../../../static/no_data.png";
function CustomizeLinks({
    links,
    sharedLinks,
    setShowAddFolderModal,
}: {
    links: Array<MainLink>;
    sharedLinks: boolean;
    setShowAddFolderModal: Function;
}) {
    return (
        <div>
            {links.map((link) => {
                if ((sharedLinks && !link.shared)||link.socialLink) return null;
                return <LinkCard setShowAddFolderModal={setShowAddFolderModal} key={link.uid} link={link} />;
            })}
            {links.length === 0 && (
                <div className="text-center my-5 text-danger h4">
                    <img src={noData} height={250} alt="no links" />
                    <div className="text-muted mt-3">
                        You don’t have any active links
                    </div>
                </div>
            )}
        </div>
    );
}

export default CustomizeLinks;
