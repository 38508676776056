import firebase from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDsCveABt9FKnpA-63h9Jn7bnwc680Bno0",
    authDomain: "oshoy-stage.firebaseapp.com",
    projectId: "oshoy-stage",
    storageBucket: "oshoy-stage.appspot.com",
    messagingSenderId: "335070823231",
    appId: "1:335070823231:web:71104fd5d9636b57e2c98a",
    measurementId: "G-3JEVLD89NP"
};
export const fire=firebase.initializeApp(firebaseConfig);
