import React, { useEffect, useState } from "react";
import { Form, InputGroup, Dropdown } from "react-bootstrap";
import { DEFAULT_SOCIAL_LINKS } from "../../../../../utils/config/social-links";
import { MainLink } from "../../../../../interfaces/main_link";

function SocialLinkCard({
    socialLink,
    linksState,
}: {
    socialLink: MainLink;
    linksState: [Array<MainLink>, any];
}) {
    const [links, setLinks] = linksState;
    let effectiveURL = socialLink.url;

    if (effectiveURL.startsWith("https://maps.google.com/?q=")) {
        effectiveURL = effectiveURL.slice(27);
    }
    const [url, setURL] = useState(
        effectiveURL === "~" ? "" : effectiveURL
    );
    const [platform, setPlatform] = useState<string>(
        socialLink.url === "~"
            ? "none"
            : socialLink.title
            ? socialLink.title
            : "none"
    );
    useEffect(() => {
        if (platform === "~") return;
        let effectiveURL = url;
        if (platform === "address" && !effectiveURL.includes("https://")) {
            effectiveURL = "https://maps.google.com/?q=" + effectiveURL;
        }
        let loopLink = links.find((link) => link.uid === socialLink.uid);
        if (loopLink) {
            loopLink.url = effectiveURL;
            return setLinks([...links]);
        }
        setLinks([...links, { platform, url }]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, platform]);
    const [shared, setShared] = useState<boolean>(socialLink.shared);

    useEffect(() => {
        let index = links.findIndex(
            (localLink) => socialLink.uid === localLink.uid
        );
        let localLinks = [...links];
        localLinks[index].shared = shared;
        setLinks(localLinks);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shared]);
    function onDelete() {
        setLinks((old: any) =>
            old.filter((link: MainLink) => link.uid !== socialLink.uid)
        );
    }
    return (
        <Form className={"my-3"}>
            <InputGroup className={"align-items-center"}>
                <InputGroup.Prepend>
                    <Dropdown>
                        <Dropdown.Toggle
                            style={{ backgroundColor: "#C1C1C4" }}
                            variant={"secondary"}
                        >
                            <img
                                src={
                                    DEFAULT_SOCIAL_LINKS[
                                        platform === "~" ? "none" : platform
                                    ]
                                }
                                alt={platform}
                            />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {Object.keys(DEFAULT_SOCIAL_LINKS).map(
                                (link: any, index) => {
                                    if (
                                        links.some(
                                            (_link) => _link.title === link
                                        ) ||
                                        link === "none"
                                    )
                                        return null;
                                    return (
                                        <Dropdown.Item
                                            key={link + index.toString()}
                                            onClick={() => {
                                                setPlatform((old) => {
                                                    for (let loopLink of links) {
                                                        if (
                                                            loopLink.title ===
                                                            old
                                                        ) {
                                                            loopLink.title = link;
                                                            setLinks([
                                                                ...links,
                                                            ]);
                                                        }
                                                    }
                                                    return link;
                                                });
                                            }}
                                        >
                                            <img
                                                alt={link}
                                                height={24}
                                                width={24}
                                                src={DEFAULT_SOCIAL_LINKS[link]}
                                            />
                                            <span className="text-capitalize m-1">
                                                {link}
                                            </span>
                                        </Dropdown.Item>
                                    );
                                }
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </InputGroup.Prepend>

                <Form.Control
                    placeholder={"Enter Link URL"}
                    className={"link-card-input"}
                    value={url}
                    onChange={(e) => {
                        setURL(e.target.value);
                    }}
                />
                <img
                    onClick={onDelete}
                    height={24}
                    className={"pointer-on-hover mx-2"}
                    alt="delete"
                    src="https://img.icons8.com/material/24/000000/delete-forever--v2.png"
                />
                <Form.Switch
                    checked={shared}
                    onChange={(e) => {
                        setShared(e.target.checked);
                    }}
                    id={socialLink.title + "switch"}
                />
            </InputGroup>
        </Form>
    );
}

export default SocialLinkCard;
