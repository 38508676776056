import "firebase/firestore";
import { fire } from "./main";
import {
    defaultLocalUser,
    defaultSharedLinksDocument,
    LocalUser,
    RemoteUser,
} from "../../interfaces/user";
import MetaData, { defaultMetaData } from "../../interfaces/metadata";

export async function createUserDocument(user_data: any) {
    console.log(user_data)
    const data = await fire.firestore().collection("MemberInfo").add(user_data);
    await fire
        .firestore()
        .collection("SharedLinks")
        .doc(data.id)
        .set({
            ...defaultSharedLinksDocument,
            name: user_data.name,
            bio: user_data.bio,
            email: user_data.email,
            username: user_data.username,
            profile_pic_url: user_data.profile_pic_url,
            
        });
}

export async function getUserDocument(
    email: string
): Promise<LocalUser | null> {
    let user_doc: LocalUser | null = null;
    const data = await fire
        .firestore()
        .collection("MemberInfo")
        .where("email", "==", email)
        .get();

    if (!data.empty && data.docs.length === 1 && data.docs[0].exists) {
        const doc_data = data.docs[0].data();
        let sharedLinksMeta ;
        const sharedLinksData = await getUserSharedLinks(data.docs[0].id);
        if (sharedLinksData && sharedLinksData.exists) {
            sharedLinksMeta = sharedLinksData.data()?.meta;
        }
        user_doc = {
            ...defaultLocalUser,
            ...doc_data,
            uid: data.docs[0].id,
            links: doc_data.links ? doc_data.links : [],
            meta: sharedLinksMeta
                ? sharedLinksMeta
                : defaultMetaData,
        };
    }
    return user_doc;
}

export async function updateUserLinks(user: LocalUser) {
    if (user.links.length !== 0) {
        if (user.links[user.links.length - 1].title === "~") return;
    }
    await fire
        .firestore()
        .collection("MemberInfo")
        .doc(user.uid)
        .update({ links: user.links });
}
export async function updateUserFolders(user: LocalUser) {
    await fire
        .firestore()
        .collection("MemberInfo")
        .doc(user.uid)
        .update({ folders: user.folders });
}

export async function updateUserProfilePictureURL(
    user: LocalUser,
    profile_pic_url: string
) {
    await fire
        .firestore()
        .collection("MemberInfo")
        .doc(user.uid)
        .update({ profile_pic_url });
    await fire
        .firestore()
        .collection("SharedLinks")
        .doc(user.uid)
        .update({ profile_pic_url });
    return profile_pic_url;
}
export async function updateUserMeta(user: LocalUser, meta: MetaData) {
    await fire
        .firestore()
        .collection("SharedLinks")
        .doc(user.uid)
        .update({ meta });
        await fire
        .firestore()
        .collection("MemberInfo")
        .doc(user.uid)
        .update({ meta });
}
export async function getUserSharedLinks(uid: string) {
    return await fire.firestore().collection("SharedLinks").doc(uid).get();
}
export async function updateUserBioAndName(
    user: LocalUser,
    bio: RemoteUser["bio"],
    name: RemoteUser["name"]
) {
    await fire
        .firestore()
        .collection("MemberInfo")
        .doc(user.uid)
        .update({ bio, name });
    await fire
        .firestore()
        .collection("SharedLinks")
        .doc(user.uid)
        .update({ bio, name });
}
