import React from "react";
import { Button, Navbar } from "react-bootstrap";
import { useHistory } from "react-router";
import { useUser } from "../../contexts/user_context";
import logo from "../../static/logo.png";
function NavigationBar({ toggleSidebar }: any) {
    const history = useHistory();
    const [user] = useUser();
    return (
        <Navbar bg="white" className="mb-2 round-border py-0">
            <Button
                variant={"light"}
                onClick={toggleSidebar}
                className={"d-lg-none mr-2"}
            >
                <img
                
                    alt="burger menu"
                    src="https://img.icons8.com/material-rounded/24/000000/menu--v1.png"
                />
            </Button>
            <Navbar.Brand className="flex-grow-1">
                <img height={60} src={logo} alt="logo" />
            </Navbar.Brand>
            {/* <div className="mx-1">My Link : <a className={'text-muted'} href={"/" + user?.username}>https://oshoy.com/{user?.username}</a></div> */}
            <Button
                onClick={() => history.push(`/${user?.username}`)}
                variant={"outline-dark "}
                className="mx-2 round-border"
            >
                <div className={"d-flex align-items-center"}>
                    <img
                        className={"m-1"}
                        alt="share"
                        src="https://img.icons8.com/android/14/000000/share.png"
                    />
                    <span className={"d-none d-md-block"}>Shared Links</span>
                </div>
            </Button>
            <Button
                onClick={() => history.push(`/allLinks/${user?.uid}`)}
                variant={"outline-dark"}
                className="mx-2 round-border"
            >
                <div className={"d-flex align-items-center"}>
                    <img
                        className={"m-1"}
                        alt="share"
                        src="https://img.icons8.com/android/14/000000/share.png"
                    />
                    <span className={"d-none d-md-block"}>All Links</span>
                </div>
            </Button>
        </Navbar>
    );
}

export default NavigationBar;
