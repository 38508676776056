import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
    signUpWithEmailPassword,
    signUpWithGoogle,
} from "../../../utils/firebase/auth";
import { createUserDocument } from "../../../utils/firebase/firestore";
import { defaultRemoteUser } from "../../../interfaces/user";
const errorTexts = {
    fieldRequired: "This field is required!",
    invalidEmail: "Invalid Email",
    minPasswordLength: "Password must be at-least 6 characters",
    emailInUse: "This email is already in use. Please sign in!",
    usernameTaken: "This username has been taken",
};
function RegisterForm() {
    const [errors, setErrors] = useState<any>({});
    const [inputUsername, setInputUsername] = useState("");

    const { register, handleSubmit } = useForm();
    async function validateUsername(username: string) {
        if (username === "") {
            setErrors({ username: errorTexts.fieldRequired });
            return false;
        }
        const data = await fetch(
            "https://us-central1-oshoy-stage.cloudfunctions.net/getSharedLinksFunction/" +
                username
        );
        const json_data = await data.json();
        if (json_data.user) {
            setErrors({ username: errorTexts.usernameTaken });
            return false;
        }
        return true;
    }
    function validateEmail(email: string) {
        if (email === "") {
            setErrors({ email: errorTexts.fieldRequired });
            return false;
        }
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            setErrors({ email: errorTexts.invalidEmail });
            return false;
        }
        return true;
    }

    function validatePassword(password: string) {
        if (password === "") {
            setErrors({ password: errorTexts.fieldRequired });
            return false;
        }
        if (password.length < 6) {
            setErrors({ password: errorTexts.minPasswordLength });
            return false;
        }
        return true;
    }
    async function googleSignUp() {
        setErrors({});
        if (!(await validateUsername(inputUsername))) return;
        signUpWithGoogle().then((result) => {
            const user_data: any = defaultRemoteUser;
            user_data.username = inputUsername;
            user_data.email = result?.user?.email;
            user_data.profile_pic_url=result?.user?.photoURL;
            user_data.name=result?.user?.displayName;
            createUserDocument(user_data);
        });
    }
    async function onSubmit(data: any) {
        setErrors({});
        const { email, password, username } = data;
        const user_data: any = defaultRemoteUser;
        user_data.email = email;
        user_data.username = username;
        if (!(await validateUsername(username))) return;
        if (!validateEmail(email)) return;
        if (!validatePassword(password)) return;
        const result = await signUpWithEmailPassword(email, password);
        if (result !== true) {
            switch (result.code) {
                case "auth/email-already-in-use": {
                    setErrors({ ...errors, email: errorTexts.emailInUse });
                    break;
                }
                default:
                    setErrors({ ...errors, password: result.message });
            }
        } else await createUserDocument(user_data);
    }
    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
            <label htmlFor="register-email">Username</label>
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>liinks.co/</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    value={inputUsername}
                    onChange={(e) => setInputUsername(e.target.value)}
                    ref={register}
                    name="username"
                    id="register-email"
                    type="text"
                    placeholder="Username"
                />
            </InputGroup>
            {errors.username && (
                <small className={"text-danger"}>{errors.username}</small>
            )}
            <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    ref={register}
                    name="email"
                    type="email"
                    placeholder="Email"
                />
                {errors.email && (
                    <small className={"text-danger"}>{errors.email}</small>
                )}
            </Form.Group>
            <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                    ref={register}
                    name="password"
                    type="password"
                    placeholder="Password"
                />
                {errors.password && (
                    <small className={"text-danger"}>{errors.password}</small>
                )}
            </Form.Group>
            <Button variant={"outline-primary"} type={"submit"} block={true}>
                Register
            </Button>
            <Button variant={"primary"} onClick={googleSignUp} block={true}>
                Sign In With Google
            </Button>
            <Form.Text className="text-muted">
                * By signing up, you consent to receiving occasional emails.
            </Form.Text>
        </Form>
    );
}

export default RegisterForm;
