import React, { FormEvent, useRef, useState } from "react";
import CustomizeLinks from "./customizeLinks/customize_links";
import { useUser } from "../../../contexts/user_context";
import { LocalUser } from "../../../interfaces/user";
import { DropdownButton, Dropdown, Modal, Form, Button } from "react-bootstrap";
import SocialLinks from "./socialLinks/social_links";
import { defaultLink, MainLink } from "../../../interfaces/main_link";
import { v4 as uuidv4 } from "uuid";
import { dummyFolder } from "../../../interfaces/folder";
import Folders from "./folders/folders";
import JSSoup from "jssoup";
import {
    updateUserFolders,
    updateUserLinks,
} from "../../../utils/firebase/firestore";
import "./customize.css";
import facebook_logo from "../../../static/facebook_logo.png";
import links_logo from "../../../static/links_logo.png";
import folders_logo from "../../../static/folders_logo.png";
function Customize({
    sharedLinks,
    isBookmarkLinks,
}: {
    sharedLinks: boolean;
    isBookmarkLinks: boolean;
}) {
    const [user, setUser] = useUser();
    const [search, setSearch] = useState("");
    const [hierarchy, setHeirarchy] = useState<
        { name: string; uid: string | null }[]
    >([{ name: "", uid: null }]);
    const [socialLinks, setSocialLinks] = useState<Array<MainLink>>(
        user
            ? user.links
                  .filter(
                      (link) => link.socialLink && (!sharedLinks || link.shared)
                  )
                  .map((link) => {
                      return { ...link };
                  })
            : []
    );
    const [socialLinksEditable, setSocialLinksEditable] = useState(false);
    const [showAddFolderModal, setShowAddFolderModal] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");
    function addFolderToHierarchy(uid: string) {
        if (user) {
            const f = user.folders.find((folder) => folder.uid === uid);
            if (f)
                setHeirarchy((old) => [...old, { name: f.name, uid: f.uid }]);
        }
    }
    // function popFolderFromHierarchy() {
    //     setHeirarchy((old) => old.slice(0, old.length - 2));
    // }
    function setCurrentHierarchy(uid: string | null) {
        if (uid === null) {
            return setHeirarchy([{ name: "", uid: null }]);
        }
        if (user)
            setHeirarchy((old) => {
                const index = old.findIndex((f) => f.uid === uid);
                if (index === -1) {
                    const f = user.folders.find((folder) => folder.uid === uid);
                    if (f)
                        return [
                            { name: "", uid: null },
                            { name: f.name, uid: f.uid },
                        ];
                    return [{ name: "", uid: null }];
                } else {
                    return old.slice(0, index + 1);
                }
            });
    }

    function parseString(str: string) {
        let soup = new JSSoup(str);
        let tags = soup.findAll("A");
        tags.forEach((tag: any) => {
            if (
                tag &&
                tag.attrs &&
                tag.attrs.HREF &&
                tag.contents.length > 0 &&
                tag.contents[0]._text
            ) {
                setUser((old: LocalUser) => {
                    const newUser = {
                        ...old,
                        links: [
                            ...old.links,
                            {
                                ...defaultLink,
                                uid: uuidv4(),
                                title: tag.contents[0]._text,
                                url: tag.attrs.HREF,
                                isBookmark: true,
                                shared: false,
                            },
                        ],
                    };
                    updateUserLinks(newUser);
                    return newUser;
                });
            } else
                alert(
                    "Error adding a bookmark. Ensure the bookmarks file is exported from a browser and is untouched."
                );
        });
        if (tags.length === 0) {
            alert(
                "No bookmarks found in the provided file. You must provide a valid bookmarks HTML file."
            );
        }
    }
    function onBookmarkChange(e: any) {
        if (e.target.files.length === 0) return;
        const file = e.target.files[0];
        if (file.type !== "text/html") {
            alert("Kindly provide a HTML file");
            return;
        }
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
            if (reader.result) parseString(reader.result.toString());
        };

        reader.onerror = function () {
            console.log(reader.error);
        };
    }
    function openBookmarkInput() {
        const ele = document.getElementById("bookmarks-file");
        if (ele) ele.click();
    }
    const oldLinks = useRef(user?.links.filter((link) => link.socialLink));
    function toggleSocialLinksEditable() {
        setSocialLinksEditable((old) => !old);
    }
    function addLink() {
        if (user?.links.length !== 0) {
            if (user?.links[user?.links.length - 1].title === "~") return;
        }
        setUser((old: LocalUser) => {
            const newUser = {
                ...old,
                links: [
                    ...old.links,
                    {
                        ...defaultLink,
                        uid: uuidv4(),
                        folder: hierarchy[hierarchy.length - 1].uid,
                    },
                ],
            };
            updateUserLinks(newUser);
            return newUser;
        });
    }
    function onSocialCancel() {
        setSocialLinks(
            user ? user.links.filter((link) => link.socialLink) : []
        );
        toggleSocialLinksEditable();
    }
    function onSocialSave() {
        setSocialLinks((old) => old.filter((link) => link.title !== "~"));
        setUser((old: LocalUser) => {
            const newUser = {
                ...old,
                links: [
                    ...socialLinks.filter((link) => link.title !== "~"),
                    ...old.links.filter((link) => !link.socialLink),
                ],
            };
            updateUserLinks(newUser);
            return newUser;
        });

        oldLinks.current = socialLinks;
        toggleSocialLinksEditable();
    }
    function addSocialLink() {
        if (socialLinks.length !== 0) {
            if (socialLinks[socialLinks.length - 1].title === "~") return;
        }

        setSocialLinks((old: MainLink[]) => {
            return [
                ...old,
                { ...defaultLink, socialLink: true, uid: uuidv4() },
            ];
        });
        setSocialLinksEditable(true);
    }
    function addFolder(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setUser((old: LocalUser) => {
            const newUser = {
                ...old,
                folders: [
                    ...old.folders,
                    {
                        ...dummyFolder,
                        name: newFolderName,
                        uid: uuidv4(),
                        parent: hierarchy[hierarchy.length - 1].uid,
                    },
                ],
            };
            updateUserFolders(newUser);
            return newUser;
        });
        setTimeout(() => setShowAddFolderModal(false), 0);
    }
    let effectiveLinks: MainLink[] = [];
    if (user)
        effectiveLinks = user.links.filter((link) => {
            if (!link.title.includes(search) && !link.url.includes(search))
                return false;
            if (isBookmarkLinks) return !link.socialLink && link.isBookmark;
            if (sharedLinks) return link.shared;
            else if (hierarchy.length > 1) {
                return (
                    !link.socialLink &&
                    link.folder === hierarchy[hierarchy.length - 1].uid
                );
            } else return !link.socialLink && !link.folder;
        });
    return (
        <div>
            <Modal
                centered={true}
                show={showAddFolderModal}
                onHide={() => setShowAddFolderModal(false)}
            >
                <Modal.Header closeButton={true}>Add Folder</Modal.Header>
                <Modal.Body>
                    <Form onSubmit={addFolder}>
                        <Form.Group>
                            <Form.Label>Folder Name</Form.Label>
                            <Form.Control
                                required={true}
                                value={newFolderName}
                                onChange={(e) =>
                                    setNewFolderName(e.target.value)
                                }
                                placeholder={"Folder Name"}
                            />
                        </Form.Group>
                        <Button type={"submit"}>Add Folder</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <div className="d-flex justify-content-between align-items-center mb-3 mx-3">
                <h2>
                    {hierarchy.map((f) => (
                        <span key={f.uid}>
                            <span className="customize-heading">
                                {f.uid !== null && ">"}
                            </span>
                            <Button
                                onClick={() => setCurrentHierarchy(f.uid)}
                                variant="link"
                                className="customize-heading"
                            >
                                {f.uid && f.name}
                                {!f.uid &&
                                    (isBookmarkLinks
                                        ? "Bookmarks"
                                        : sharedLinks
                                        ? "Shared Links"
                                        : "All Links")}
                            </Button>
                        </span>
                    ))}
                </h2>

                <input
                    type="file"
                    id="bookmarks-file"
                    onChange={onBookmarkChange}
                    className="d-none"
                    accept="text/html"
                />
                <DropdownButton
                    style={{ height: "40px" }}
                    id="dropdown-basic-button"
                    title="+ Add New"
                >
                    <Dropdown.Item onClick={addLink}>
                        <img className="mr-2" src={links_logo} alt="link" />
                        Website Link
                    </Dropdown.Item>
                    <hr className="my-1 mx-3" />
                    <Dropdown.Item onClick={addSocialLink}>
                        <img className="mr-2" src={facebook_logo} alt="link" />
                        Social Media Link
                    </Dropdown.Item>
                    <hr className="my-1 mx-3" />
                    <Dropdown.Item
                        onClick={() => {
                            setShowAddFolderModal(true);
                        }}
                    >
                        <img className="mr-2" src={folders_logo} alt="link" />
                        Folder
                    </Dropdown.Item>
                    <hr className="my-1 mx-3" />
                    <Dropdown.Item
                        onClick={() => {
                            openBookmarkInput();
                        }}
                    >
                        Bookmarks
                    </Dropdown.Item>
                </DropdownButton>
            </div>
            <div className="ml-3">
                <Form.Group>
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                        placeholder="Search Link Title/URL"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Form.Group>
            </div>

            {!isBookmarkLinks&& !sharedLinks && user && user.folders.length > 0 && (
                <Folders
                    parent={hierarchy[hierarchy.length - 1]}
                    addFolderToHierarchy={addFolderToHierarchy}
                />
            )}
            {user && effectiveLinks.length > 0 && (
                <h4 className="ml-3">Links:</h4>
            )}
            {!isBookmarkLinks && hierarchy.length <= 1 && user && (
                <SocialLinks
                    sharedLinks={sharedLinks}
                    toggleSocialLinksEditable={toggleSocialLinksEditable}
                    editable={socialLinksEditable}
                    setSocialLinks={setSocialLinks}
                    onSocialCancel={onSocialCancel}
                    socialLinks={[...socialLinks]}
                    onSocialSave={onSocialSave}
                />
            )}
            {user && (
                <CustomizeLinks
                    sharedLinks={sharedLinks}
                    links={effectiveLinks}
                    setShowAddFolderModal={setShowAddFolderModal}
                />
            )}
        </div>
    );
}

export default Customize;
