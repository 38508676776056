import {fire} from "./main";
import {updateUserProfilePictureURL} from "./firestore";
import 'firebase/storage';

export const uploadImage=async (user:any,file:any)=>{
    const ref=fire.storage().ref(`${user.email}/pp/${file.name}`);
    await ref.put(file);
    const url=await ref.getDownloadURL();
    return updateUserProfilePictureURL(user,url);
}
export const uploadButtonImage=async (user:any,file:any)=>{
    const ref=fire.storage().ref(`${user.email}/buttonImage/${file.name}`);
    await ref.put(file);
    return await ref.getDownloadURL();
}