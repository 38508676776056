import React from 'react';

function Loading() {
    return (
        <div className={'vh-100 d-flex align-items-center justify-content-center'}>
            <div className={'display-3'}>loading...</div>
        </div>
    );
}

export default Loading;