export default interface Folder{
    name:string;
    uid:string;
    parent:string|null;
}
export const dummyFolder:Folder={
    name:'',
    uid:'',
    parent:null
}
