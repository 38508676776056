import React from "react";
import styles from "./sidebar.module.css";
import { useUser } from "../../../contexts/user_context";
import { LocalUser } from "../../../interfaces/user";
import { signOut } from "../../../utils/firebase/auth";
import links from "../../../static/sidebarIcons/links.png";
import design from "../../../static/sidebarIcons/design.png";
import settings from "../../../static/sidebarIcons/settings.png";
import bookmarks from "../../../static/sidebarIcons/bookmarks.png";
import logout from "../../../static/sidebarIcons/logout.png";

function Sidebar({ selected, setSelected }: any) {
    const [user]: LocalUser | null | any = useUser();
    const tabs = [
        {
            heading: "My Page",
            items: [
                {
                    name: "Links",
                    icon: links,
                    subItems: [
                        { name: "All Links", size: user?.links.length },
                        {
                            name: "Shared Links",
                            size: user?.meta.sharedLinks.length,
                        },
                    ],
                },
                { name: "Bookmarks", icon: bookmarks },
                { name: "Design", icon: design },
            ],
        },
        {
            heading: "Account",
            items: [
                { name: "Settings", icon: settings },
                {
                    name: "Logout",
                    icon: logout,
                    onClick: () => {
                        signOut();
                    },
                },
            ],
        },
    ];
    return (
        <div
            style={{ height: "100%", maxWidth: 238 }}
            className={
                "bg-white round-border p-3 d-flex flex-column position-relative"
            }
        >
            <div className={"flex-grow-1"}>
                {tabs.map((tab, index) => (
                    <div key={index}>
                        <div className={styles.heading}>{tab.heading}</div>
                        {tab.items.map((item: any) => {
                            const isSelected =
                                selected === item.name ||
                                item.subItems?.some(
                                    (item2: any) => item2.name === selected
                                );
                            return (
                                <div
                                    key={item.name}
                                    className={
                                        isSelected
                                            ? styles.itemSelected
                                            : styles.item
                                    }
                                >
                                    {isSelected && (
                                        <span
                                            className="position-absolute text-right"
                                            style={{
                                                left: 0,
                                                width: 50,
                                                height: 30,
                                                borderTopRightRadius: "8px",
                                                borderBottomRightRadius: "8px",
                                                backgroundColor: "#5B7DFB",
                                            }}
                                        >
                                            <img
                                                className="mr-2 pr-1"
                                                src={item.icon}
                                                alt="item.name"
                                            />
                                        </span>
                                    )}
                                    <div className="d-flex">
                                        <img
                                            className="mr-3 align-self-center"
                                            src={item.icon}
                                            alt="item.name"
                                        />
                                        <div
                                            onClick={() => {
                                                if (item.subItems?.length > 0) {
                                                    setSelected(
                                                        item.subItems[0].name
                                                    );
                                                } else setSelected(item.name);
                                                if (item.onClick)
                                                    item.onClick();
                                            }}
                                            className=""
                                        >
                                            {item.name}
                                            {isSelected&&item.subItems && (
                                                <svg
                                                    width="15"
                                                    height="8"
                                                    viewBox="0 0 15 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="ml-2"
                                                >
                                                    <path
                                                        d="M7.00125 7.00125C6.7676 7.00171 6.54116 6.92033 6.36125 6.77125L0.361252 1.77125C0.157036 1.60151 0.0286112 1.3576 0.0042315 1.09318C-0.0201482 0.82875 0.0615137 0.565469 0.231252 0.361252C0.400991 0.157035 0.644902 0.0286112 0.909329 0.00423152C1.17375 -0.0201482 1.43704 0.0615132 1.64125 0.231252L7.00125 4.71125L12.3613 0.391253C12.4635 0.308187 12.5812 0.246155 12.7076 0.208723C12.8339 0.171291 12.9664 0.159198 13.0974 0.173137C13.2285 0.187076 13.3554 0.226773 13.4711 0.289947C13.5867 0.35312 13.6887 0.438526 13.7713 0.541252C13.8628 0.644074 13.9322 0.7647 13.975 0.895574C14.0178 1.02645 14.0331 1.16475 14.0199 1.30181C14.0068 1.43887 13.9655 1.57175 13.8986 1.6921C13.8317 1.81246 13.7407 1.91771 13.6313 2.00125L7.63125 6.83125C7.44617 6.95677 7.22435 7.01662 7.00125 7.00125Z"
                                                        fill="#5B7DFB"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                    </div>

                                    {isSelected && (
                                        <div className="m-2">
                                            {item.subItems?.map(
                                                (item2: any) => (
                                                    <div
                                                        key={item2.name}
                                                        onClick={() => {
                                                            setSelected(
                                                                item2.name
                                                            );
                                                        }}
                                                        className={
                                                            "m-2 " +
                                                            (selected ===
                                                            item2.name
                                                                ? styles.subItemSelected
                                                                : styles.subItemUnselected)
                                                        }
                                                    >
                                                        {item2.name}(
                                                        {item2.size})
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
            <div className={styles.userTab + " d-flex align-items-center"}>
                <img
                    alt="user"
                    className="m-1"
                    height={35}
                    width={35}
                    src={
                        user?.profile_pic_url
                            ? user?.profile_pic_url
                            : "https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg"
                    }
                />
                <div>
                    <div className={styles.username}>{user.username}</div>
                    <div className={styles.userEmail}>{user.email}</div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
