import MetaData from "../../interfaces/metadata";

export const BUTTON_LAYOUTS: MetaData['buttonType'][] = [
    "sharp-rectangle",
    "smooth-rectangle",
    "pill",
    "sharp-square",
    "smooth-square",
    "circle"
]
export const BUTTON_COLOURS=[
    {name:"red",color:"#DE3163",textColor:"#FFFFFF"},
    {name:"blue",color:"#6495ED",textColor:"#FFFFFF"},
    {name:"yellow",color:"#ffea29",textColor:"#4c4c4c"},
    {name:"green",color:"#b3ff00",textColor:"#4c4c4c"},
]