import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Container, Modal, Button, Navbar, Nav } from "react-bootstrap";
import RegisterForm from "./registerForm/register_form";
import LoginForm from "./loginForm/login_form";
import "./landingPage.scss";
import jumbo_phone_image from "./images/jumbo_phone_image.png";
import feature1 from "./images/feature-1.png";
import feature2 from "./images/feature-2.png";
import feature3 from "./images/feature-3.png";
import facebook from "./images/facebook.png";
import linkedin from "./images/linkedin.png";
import twitter from "./images/twitter.png";
import logo from "./images/logo.png";
function LandingPage() {
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [registerModalShow, setRegisterModalShow] = useState(false);
  const showLoginModal = () => setLoginModalShow(true);
  const hideLoginModal = () => setLoginModalShow(false);
  const showRegisterModal = () => setRegisterModalShow(true);
  const hideRegisterModal = () => setRegisterModalShow(false);

  function PlanList({ text }: { text: string }) {
    return (
      <div className="plan-div d-flex align-items-center my-3">
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          
        >
          <circle cx="5" cy="5" r="5" fill="#5B7DFB" className="bullet" />
        </svg>

        <div className="plan-text ml-3">{text}</div>
      </div>
    );
  }

  function FeatureItem({
    icon,
    heading,
    body,
  }: {
    icon: any;
    heading: string;
    body: string;
  }) {
    return (
      <div className="feature py-4 py-md-2 col-12 col-md-4 d-flex flex-column align-items-center">
        <div className="icon-holder d-flex align-items-center justify-content-center">
          <img src={icon} alt="heading" />
        </div>
        <div className="heading my-3">{heading}</div>
        <div className="text">{body}</div>
      </div>
    );
  }

  function TestimonialCard({
    text,
    name,
    designation,
    color,
  }: {
    text: string;
    name: string;
    designation: string;
    color: string;
  }) {
    return (
      <div className="testimonial m-3" style={{ width: 343 }}>
        <div style={{ backgroundColor: color, height: 15 }} />
        <div className="p-4">
          <div className="text mb-5 pb-5">{text}</div>
          <div className="name">{name}</div>
          <div className="designation">{designation}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal show={loginModalShow} onHide={hideLoginModal} centered={true}>
        <Modal.Header closeButton className="text-center">
          <h3 className="w-100">Sign In</h3>
        </Modal.Header>
        <Modal.Body>
          <LoginForm />
        </Modal.Body>
      </Modal>
      <Modal
        show={registerModalShow}
        onHide={hideRegisterModal}
        centered={true}
      >
        <Modal.Header closeButton className="text-center">
          <h3 className="w-100">Register with Email</h3>
        </Modal.Header>
        <Modal.Body>
          <RegisterForm />
        </Modal.Body>
      </Modal>
      <Container>
        <Navbar>
          <Navbar.Brand>
            <img src={logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto align-items-center">
              <Nav.Link style={{ color: "#494949" }}>About</Nav.Link>
              <Nav.Link onClick={showLoginModal} style={{ color: "#5B7DFB" }}>
                Login
              </Nav.Link>
              <Nav.Link>
                <Button
                  onClick={showRegisterModal}
                  className="rounded-pill landing-page-nav-button"
                  variant={"outline-primary"}
                >
                  Sign Up Free
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="jumbo align-items-center d-flex justify-content-between flex-wrap-reverse">
          <div className="typography col-12 col-lg-6">
            <div className="heading mb-4">
              Share more with your{" "}
              <span className="blue-text">link in bio</span>.
            </div>
            <div className="text mb-5">
              Tapl Me is a beautiful way to share your YouTube, Instagram,
              Twitter, and more content with just one bio link. Your bio is
              free. Get started today!
            </div>
            <Button onClick={showRegisterModal} className="sign-up-button">
              Sign Up Free
            </Button>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center py-5 p-md-2">
            <img height={500} alt="phone vector" src={jumbo_phone_image} />
          </div>
        </div>
        <div className="features mt-5 pt-5 pb-5">
          <div className="header mt-4 mb-4">
            <div className="heading mb-4">
              Share one link across many social platforms
            </div>
            <div className="text">
              Tapl Me helps you create one link that directs your audience to
              all your social media profiles, articles, events, sites and shops.
              Simply sign up and import your favorite posts, pics, and media
              from your profiles across the web.
            </div>
          </div>
          <div className="feature-list d-flex flex-wrap justify-content-between py-5">
            <FeatureItem
              icon={feature3}
              heading={"One link everywhere"}
              body={
                "Create one link for all your websites and share them everywhere"
              }
            />
            <FeatureItem
              icon={feature1}
              heading={"Link analytics"}
              body={"Get detailed link click stats to understand your audience"}
            />
            <FeatureItem
              icon={feature2}
              heading={"Customize your layout "}
              body={
                "Design your layout with just a few clicks and present it in endless ways"
              }
            />
          </div>
        </div>
        <div className="testimonials mt-4 p-4">
          <div className="heading mb-5 mt-4">Loved by users worldwide</div>
          <div className="list d-flex justify-content-center flex-wrap flex-md-nowrap">
            <TestimonialCard
              text={
                "Very interesting concept. So much potential within the targeted marketing realm."
              }
              name={"John Doe"}
              designation={"Social media influencer"}
              color={"#FEBF3266"}
            />
            <TestimonialCard
              text={
                "Tapl Me is easy to use and packed full of features, probably one of the best for creators to link, share content all from the Bio."
              }
              name={"John Doe"}
              designation={"Social media influencer"}
              color={"#36B00066"}
            />
            <TestimonialCard
              text={"Cool product and super smooth to setup on mobile 👍"}
              name={"John Doe"}
              designation={"Social media influencer"}
              color={"#0078FF66"}
            />
          </div>
        </div>
        <div className="plans my-4 py-5">
          <div className="heading mb-5 text-center">
            Want more features? Choose from our premium plans
          </div>
          <div className="d-flex flex-wrap justify-content-around pt-3">
            <div className="plan-card m-4 m-md-0 p-4 py-5">
              <div className="mx-3 header mb-4 d-flex align-items-center justify-content-between">
                <div className="heading text-left">
                  Starter
                  <hr className="underline mx-0" />
                </div>
                <div className="pricing text-right">
                  <div className="price m-0">Free</div>
                  <div className="description m-0">With restrictions</div>
                </div>
              </div>
              <div className="mx-3 plan-details">
                <div className="this-plan-includes">This plan includes:</div>
                <PlanList text={"Your Custom Profile/Bio Url"} />
                <PlanList text={"Your Custom Profile/Bio Url"} />
                <PlanList text={"Your Custom Profile/Bio Url"} />
                <PlanList text={"Limited access to links and buttons"} />
                <PlanList text={"Your Custom Profile/Bio Url"} />
                <PlanList text={"Your Custom Profile/Bio Url"} />
              </div>
              <Button className="plan-button btn-block mt-5">Get Started Now</Button>
            </div>
            <div className="plan-card-invert m-4 m-md-0 p-4 py-5">
              <div className="mx-3 header mb-4 d-flex align-items-center justify-content-between">
                <div className="heading text-left">
                  Starter
                  <hr className="underline mx-0" />
                </div>
                <div className="pricing text-right">
                  <div className="price m-0">Free</div>
                  <div className="description m-0">With restrictions</div>
                </div>
              </div>
              <div className="mx-3 plan-details">
                <div className="this-plan-includes">This plan includes:</div>
                <PlanList text={"Your Custom Profile/Bio Url"} />
                <PlanList text={"Your Custom Profile/Bio Url"} />
                <PlanList text={"Your Custom Profile/Bio Url"} />
                <PlanList text={"Limited access to links and buttons"} />
                <PlanList text={"Your Custom Profile/Bio Url"} />
                <PlanList text={"Your Custom Profile/Bio Url"} />
              </div>
              <Button className="plan-button btn-block mt-5">Get Started Now</Button>
            </div>
          </div>
        </div>
      </Container>
      <Container className="footer py-4 mt-5" fluid={true}>
        <Container className="d-flex justify-content-between align-items-center">
          <div className="left-footer d-flex align-items-center">
            <div className="mr-3">
              <img src={logo} alt="logo" />
            </div>
            <div>
              <div className="for-any-query">For any queries message</div>
              <div className="email">support@gmail.com</div>
            </div>
          </div>
          <div className="right-footer">
            <div className="follow-us-on">Follow us on</div>
            <div className="social-links d-flex justify-content-around">
              <div className="social-link rounded-circle d-flex align-items-center justify-content-center">
                <img src={facebook} alt="facebook" />
              </div>
              <div className="social-link rounded-circle d-flex align-items-center justify-content-center">
                <img src={twitter} alt="twitter" />
              </div>
              <div className="social-link rounded-circle d-flex align-items-center justify-content-center">
                <img src={linkedin} alt="linkedin" />
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
}

export default LandingPage;
