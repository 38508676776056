import MetaData from "../../../../interfaces/metadata";
import { MainLink } from "../../../../interfaces/main_link";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "./preview_button.css";

export default function PreviewButton({
    meta,
    link,
}: {
    meta: MetaData;
    link: MainLink;
}) {
    const [focus, setFocus] = useState(false);
    useEffect(() => {
        setFocus(false);
    }, [meta.buttonColor]);
    const height = 57;
    let w: string | number = "100%";
    let radius = "0";
    let imageOnly = false;
    let imageAvailable = true;
    let title = "";
    if (link.imageURL == null || link.imageURL === "") {
        imageAvailable = false;
        for (let word of link.title.split(" ")) {
            title += word[0].toUpperCase();
        }
    }
    if (
        meta.buttonType === "smooth-rectangle" ||
        meta.buttonType === "smooth-square"
    )
        radius = "0.5em";
    if (meta.buttonType === "pill") radius = "2em";
    if (meta.buttonType === "circle") radius = "50%";
    if (["circle", "smooth-square", "sharp-square"].includes(meta.buttonType)) {
        w = height;
        imageOnly = true;
    }
    return (
        <div className={"m-1 " + (!imageOnly ? " col-12" : "")}>
            <Button
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                className={imageOnly ? "p-0" : ""}
                style={{
                    borderRadius: radius,
                    width: w,
                    minHeight: height,
                    backgroundColor: meta.buttonColor,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#e9e9e9",
                    color: meta.buttonTextColor,
                    boxShadow: `0px 0px 0px ${focus ? 0.2 : 0}rem ${
                        meta.buttonColor
                    }7F`,
                }}
                variant={"light"}
                onClick={() => window.open(link.url, "_blank", "")}
            >
                <div
                    className={
                        "d-flex align-items-center" +
                        (!link.imageURL ? " justify-content-center" : "")
                    }
                >
                    {link.imageURL && imageAvailable && (
                        <img
                            alt="buttonImage"
                            style={{ borderRadius: radius }}
                            className={!imageOnly ? "mr-2" : ""}
                            height={imageOnly ? height : height - 15}
                            width={imageOnly ? height : height - 15}
                            src={link.imageURL}
                        />
                    )}
                    {imageOnly && !imageAvailable && <div>{title}</div>}
                    {!imageOnly && (
                        <div>
                            <div className="overflow-hidden">{link.title}</div>
                            <div className={"small"}>{link.description}</div>
                        </div>
                    )}
                </div>
            </Button>
        </div>
    );
}
