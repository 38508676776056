import { MainLink } from "./main_link";

export default interface MetaData{
    buttonType:"sharp-rectangle"|"smooth-rectangle"|"pill"|"sharp-square"|"smooth-square"|"circle",
    buttonColor:string,
    buttonTextColor:string,
    sharedLinks:MainLink[]
}

export const defaultMetaData:MetaData={
    buttonType:"sharp-rectangle",
    buttonColor:"#FF0000",
    buttonTextColor:"#FFFFFF",
    sharedLinks:[]
}