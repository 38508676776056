import React from 'react';
import {Container} from "react-bootstrap";
import {LocalUser} from "../../../interfaces/user";
import './preview_structure.css';
import PreviewContent from "./preview_content";
function PreviewStructure({user}:{user:LocalUser|null|undefined}) {
    return (
        <div style={{height:"100%"}} className=" text-center bg-white round-border pt-4">
            <h3 className="text-left mx-4">Preview</h3>
            <Container style={{maxWidth:"400px"}} className="d-flex overflow-auto pb-4 flex-column align-items-center mobile-container my-4 ">
                <div className="notch"/>
                <PreviewContent allLinks={false} user={user}/>
            </Container>

        </div>
    );
}

export default PreviewStructure;
