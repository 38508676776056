import "firebase/auth";
import firebase from "firebase/app";
import { fire } from "./main";

const provider = new firebase.auth.GoogleAuthProvider();

export async function signUpWithGoogle() {
    return await fire.auth().signInWithPopup(provider)
}

export async function signUpWithEmailPassword(email: string, password: string) {
    try {
        await fire.auth().createUserWithEmailAndPassword(email, password);
        return true;
    } catch (err) {
        return err;
    }
}

export async function signInWithEmailPassword(email: string, password: string) {
    try {
        await fire.auth().signInWithEmailAndPassword(email, password);
        return true;
    } catch (err) {
        return err;
    }
}

export async function signOut() {
    await fire.auth().signOut();
}
