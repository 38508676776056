import React from 'react';
import './App.css';
import LandingPage from "./pages/landingPage/landing_page";
import {useUser} from "./contexts/user_context";
import Home from "./pages/home/home";
import UserProfile from "./pages/userProfile/user_profile";
import {Switch,Route,BrowserRouter} from 'react-router-dom';
import Loading from "./pages/loading/loading";
function App() {
    const [user]=useUser();
  if(user===undefined)return <Loading/>
  return (
    <div className="App" >
        <BrowserRouter>
            <Switch>
                <Route exact={true} path='/'>
                    {user?
                        <Home/>
                        :
                        <LandingPage/>}
                </Route>
                <Route path='/allLinks/:uid'>
                    <UserProfile/>
                </Route>
                <Route path='/:username'>
                    <UserProfile/>
                </Route>
                
            </Switch>
        </BrowserRouter>



    </div>
  );
}

export default App;
