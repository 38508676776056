export interface MainLink {
    title: string;
    description: string | null;
    url: string;
    socialLink: boolean;
    imageURL: string | null;
    shared: boolean;
    uid: string;
    folder: string | null;
    isBookmark: boolean;
}

export const defaultLink: MainLink = {
    title: "~",
    description: null,
    url: "",
    socialLink: false,
    imageURL: null,
    shared: true,
    uid: "",
    folder: null,
    isBookmark: false,
};
