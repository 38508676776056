import {BUTTON_COLOURS} from "../../../../utils/config/buttons";
import {Button} from "react-bootstrap";
import {useUser} from "../../../../contexts/user_context";
import {LocalUser} from "../../../../interfaces/user";
import {updateUserMeta} from "../../../../utils/firebase/firestore";

export default function DesignThemes() {
    const [user,setUser]=useUser();
    async function onThemeChange(buttonColor:string,buttonTextColor:string){
        if(!user)return;
        await updateUserMeta(user,{...user.meta,buttonColor,buttonTextColor});
        setUser((old:LocalUser)=>{
            return {...old,meta:{...old.meta,buttonColor,buttonTextColor}
        }})
    }
    return (
        <div>
            {BUTTON_COLOURS.map((color)=><Button variant={'light'} key={color.name} className="m-2" style={{backgroundColor:color.color, color:color.textColor, border:0}} onClick={()=>onThemeChange(color.color,color.textColor)}>{color.name}</Button>)}
        </div>
    );
}