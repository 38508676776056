import {Card} from "react-bootstrap";
import DesignButtons from "./designButtons/design_buttons";
import DesignThemes from "./designThemes/design_themes";

export default function Design() {

    return (
        <div className={'mx-3'}>
            <h2>Design</h2>
            
            <Card className={'shadow-2 my-4'}>
                <Card.Body>
                    <h6>Your Profile layout</h6>
                </Card.Body>
            </Card>
            <Card className={'shadow-2 my-4'}>
                <Card.Body>
                    <h6>Themes</h6>
                    <DesignThemes/>
                </Card.Body>
            </Card>
            <Card className={'shadow-2 my-4'}>
                <Card.Body>
                    <h6>Buttons</h6>
                    <DesignButtons/>
                </Card.Body>
            </Card>
        </div>
    );
}