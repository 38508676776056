import React from "react";
import SocialLinkCard from "./socialLinkCard/social_link_card";
import { Card, Button } from "react-bootstrap";
import { DEFAULT_SOCIAL_LINKS } from "../../../../utils/config/social-links";
import { useUser } from "../../../../contexts/user_context";
import { MainLink } from "../../../../interfaces/main_link";

interface propTypes {
    socialLinks: Array<MainLink>;
    onSocialCancel: any;
    onSocialSave: any;
    setSocialLinks: any;
    editable: boolean;
    toggleSocialLinksEditable: any;
    sharedLinks: boolean;
}

function SocialLinks({
    socialLinks,
    onSocialCancel,
    onSocialSave,
    setSocialLinks,
    editable,
    toggleSocialLinksEditable,
    sharedLinks,
}: propTypes) {
    const [user] = useUser();
    return (
        <>
            {editable && (
                <Card className={"shadow-2 m-3 link-card"}>
                    <Card.Body>
                        <h5>Social Media Links</h5>
                        {socialLinks.map((socialLink, index) => {
                            if (
                                index >=
                                Object.keys(DEFAULT_SOCIAL_LINKS).length - 1
                            )
                                return null;
                            return (
                                <SocialLinkCard
                                    key={socialLink.title + index.toString()}
                                    linksState={[socialLinks, setSocialLinks]}
                                    socialLink={socialLink}
                                />
                            );
                        })}
                        <hr />

                        <div className="d-flex justify-content-end">
                            <Button
                                block={true}
                                className={"round-border col-5 col-lg-3 mx-2"}
                                variant={"outline-primary"}
                                onClick={onSocialCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                block={true}
                                className={
                                    "round-border col-5 col-lg-3 mx-2 my-0"
                                }
                                variant={"primary"}
                                onClick={onSocialSave}
                            >
                                Save
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            )}
            {!editable &&
                user &&
                user.links.filter(
                    (link) => link.socialLink && (!sharedLinks || link.shared)
                ).length > 0 && (
                    <Card className={"shadow-2 m-3 link-card"}>
                        <Card.Body>
                            <div className={"d-flex"}>
                                <h5 className={"flex-grow-1"}>
                                    Social Media Links
                                </h5>
                                <img
                                    height={24}
                                    className={"pointer-on-hover mx-2"}
                                    onClick={toggleSocialLinksEditable}
                                    alt="edit"
                                    src="https://img.icons8.com/material-rounded/24/000000/edit--v1.png"
                                />
                            </div>
                            <hr />
                            {user?.links
                                .filter(
                                    (link) =>
                                        link.socialLink &&
                                        (!sharedLinks || link.shared)
                                )
                                .map((socialLink) => {
                                    let effectiveDisplay = socialLink.url;
                                    let effectiveURL = socialLink.url;
                                    if (socialLink.title === "phone") {
                                        effectiveURL = "tel:" + effectiveURL;
                                    }
                                    if (
                                        effectiveDisplay.startsWith(
                                            "https://maps.google.com/?q="
                                        )
                                    ) {
                                        effectiveDisplay = effectiveDisplay.slice(
                                            27
                                        );
                                    }
                                    return (
                                        <div
                                            key={socialLink.title}
                                            className="m-2"
                                        >
                                            <img
                                                alt={socialLink.title}
                                                src={
                                                    DEFAULT_SOCIAL_LINKS[
                                                        socialLink.title === "~"
                                                            ? "none"
                                                            : socialLink.title
                                                    ]
                                                }
                                            />
                                            <a
                                                href={effectiveURL}
                                                className={"text-muted h6 ml-2"}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {effectiveDisplay}
                                            </a>
                                        </div>
                                    );
                                })}
                        </Card.Body>
                    </Card>
                )}
        </>
    );
}

export default SocialLinks;
