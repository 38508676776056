import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {LocalUser} from "../../interfaces/user";
import PreviewContent from "../home/preview/preview_content";
import Loading from "../loading/loading";

const SHARED_FUNCTION_LINK='https://us-central1-oshoy-stage.cloudfunctions.net/getSharedLinksFunction/';
const MEMBERINFO_FUNCTION_LINK="https://us-central1-oshoy-stage.cloudfunctions.net/getMemberInfoFunction/";

function UserProfile() {
    const params:any=useParams();
    const [user,setUser]=useState<LocalUser|undefined|null>(undefined);

    useEffect(()=>{
        setUser(undefined);
        if(params.username)
        fetch(SHARED_FUNCTION_LINK+params.username).then((data)=>{
            data.json().then(data=>setUser(data.user))
        })
        if(params.uid)
        fetch(MEMBERINFO_FUNCTION_LINK+'uid/'+params.uid).then((data)=>{
            data.json().then(data=>setUser(data.user))
        })
    },[params.username,params.uid])
    if(user===null) return <h2 className="display-4">Not Found</h2>
    if(user===undefined) return <Loading/>
    return (
        <div className={'d-flex flex-column min-vh-100 align-items-center'}>
            <PreviewContent allLinks={params.uid?true:false} user={user}/>

        </div>
    );
}

export default UserProfile;