import React, { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { useUser } from '../../../contexts/user_context';
import { LocalUser } from '../../../interfaces/user';
import { updateUserBioAndName } from '../../../utils/firebase/firestore';
import { uploadImage } from '../../../utils/firebase/storage';

export default function Settings() {
    const [user,setUser]=useUser();
    const [name,setName]=useState(user?user.name:"");
    const [bio,setBio]=useState(user?user.bio:"");
    function onImageChange(e: any) {
        const file=e.target.files[0];
        if (!file.type.startsWith("image/")) {
            alert("Kindly provide a Image file");
            return;
        }
        uploadImage(user, file).then((profile_pic_url) => {
            setUser((old:LocalUser)=>{return {...old,profile_pic_url}});
        });
    }
    async function onSave(){
        if(!user)return;
        await updateUserBioAndName(user,bio,name);
        setUser((old:LocalUser)=>{return {...old,name,bio}});
    }
    return (
        <div>
            <Card className={'shadow-2 mb-4'}>
                <Card.Body className='d-flex flex-column align-items-center'>
                    <img alt="user" className="rounded-circle mt-3 mb-2" height={100} width={100} src={user?.profile_pic_url?user?.profile_pic_url:"https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg"}/>
                    <input id="choose_pp" style={{
                        display:"none"
                    }} type="file" accept="image/*" onChange={onImageChange}/>
                    <Button className='font-weight-bold' variant={'link'} onClick={()=>{document.getElementById('choose_pp')?.click()}}>Edit profile picture</Button>

                    <Form.Group className={'col-12'}>
                        <Form.Label>Profile Name</Form.Label>
                        <Form.Control placeholder={'Your profile name'} className={'link-card-input'} value={name} onChange={(e) => setName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className={'col-12'}>
                        <Form.Label>Profile Bio</Form.Label>
                        <Form.Control as={'textarea'} placeholder={'Enter a bio description to appear in your profile'} className={'col-12 link-card-input'} value={bio} onChange={(e) => setBio(e.target.value)}/>
                    </Form.Group>
                    <hr/>
                    <div className="d-flex justify-content-end w-100">
                        <Button block={true} className={'round-border col-5 col-lg-3 mx-2 my-0'} variant={'primary'} onClick={onSave}>Update</Button>

                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}
