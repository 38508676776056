import {createContext, useContext, useEffect, useState} from "react";
import {LocalUser} from "../interfaces/user";
import {fire} from "../utils/firebase/main";
import {getUserDocument} from "../utils/firebase/firestore";

const user_context=createContext<[LocalUser|null|undefined,any]>([null,null]);

export function useUser(){
    return useContext(user_context);
}

export default function UserContext({children}:any){
    const [user,setUser]=useState<LocalUser|null|undefined>(undefined)
    useEffect(()=>{
        fire.auth().onAuthStateChanged(user=>{
            if(user&&user.email){
                getUserDocument(user.email).then(user_doc=>{  
                    setUser(user_doc);
                });
            } else setUser(null);
        })
    },[])
    return <user_context.Provider value={[user,setUser]}>
        {children}
    </user_context.Provider>
}