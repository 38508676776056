import React from 'react'
import Customize from '../customize/customize'

export default function Bookmarks() {
    return (
        <div>
            <Customize isBookmarkLinks={true} sharedLinks={false}/>
        </div>
    )
}
